import { Settings } from '../app/shared/models/settings';

// The list of which env maps to which file can be found in `.angular-cli.json`.
const settings: Settings = {
  apiProtocol: 'https',
  avlApiHost: 'develop.api.avl.dev.afaqy.pro',
  sensewareApiHost: 'api.dev.senseware.afaqy.pro/api/v1',
  jsonHost: 'http://localhost:4200',
  stompConfig: {
    url: 'https://stageavl.afaqy.sa:12163/socket',
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
    heartbeat_in: 0,
    heartbeat_out: 50000,
    reconnect_delay: 0,
    debug: true,
  },
  pagination: {
    limit: 1000,
  },
  WSStompUnitsURL: 'https://web-notifier.avl.dev.afaqy.pro/socket',
  geoSearchProviderUrl: 'https://nominatim.afaqy.pro/',
  AVL_Login_URL: 'https://develop.avl.dev.afaqy.pro/auth/login',
  intervalGap: 15,
  signalIntervalTimeout: 60,
  aggregatedSensorTypes: [
    'temperature',
    'humidity',
    'air_quality_co2',
    'no2',
    'so2',
    'o2',
    'co',
    'tpm',
  ],
  directSensorTypes: ['in_battery_volt', 'rssi'],
  ignoreSensewareAppEnabled: true,

  // apiPort:3000
};
export const environment = {
  production: false,
  settings,
};
